<template>
  <div class="ind_news">
    <div class="container">

     <div class="main clearfix">
<!--        <div class="news-swiper">-->
<!--          <swiper :options="swiperOption">-->
<!--            <swiper-slide v-for="(item,index) in newsInfo" :key="index">-->
<!--              <a href="javascript:;" @click="goToDtl(item.id)">-->
<!--                <img :src="item.picurl" :alt="item.title" :style="{'height': imgH + 'px'}">-->
<!--              </a>-->
<!--              <span>{{item.title}}</span>-->
<!--            </swiper-slide>-->
<!--            <div class="swiper-pagination" slot="pagination"></div>-->
<!--            <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--            <div class="swiper-button-next" slot="button-next"></div>-->
<!--          </swiper>-->
<!--        </div>-->

       <div class="home_news_banner fl">
         <el-carousel height="266px">
           <el-carousel-item v-for="(item, index) in newsInfo" :key="index">
             <div class="goLink" @click="goToDtl(item.id)">
               <el-image fit="cover" style="height: 100%;width: 100%" :src="item.picurl"></el-image>
               <span>{{ item.title }}</span>
             </div>
           </el-carousel-item>
         </el-carousel>
       </div>
        <div class="latest_news fl">
          <div class="latest_top clearfix">
            <ul class="clearfix fl">
              <li rel="0" class="on"><a href="javascript:void(0);">最新动态</a></li>
              <!--	<li class="line"></li>
                <li rel="1"><a href="javascript:void(0);">媒体视角</a></li>-->
            </ul>
            <a href="/news/index.html" class="more fr">More+</a>
          </div>
          <div class="latest_tabs">
            <div class="latest_list">
              <ul>
                <li v-for="(item, index) in newsInfo" :key="index">
                  <a class="clearfix" href="javascript:;" @click="goToDtl(item.id)">
                    <div class="title ellipsis">{{ item.title }}</div>
                    <i class="hot"></i>
                    <span class="date fr">{{ item.insertTime }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="ind_notice fr">
          <div class="latest_notice clearifx">
            <i></i>
            <div class="cont">
              <h3>通知公告</h3>
              <!-- <p>实时关注第一手资讯动态</p> -->
            </div>
          </div>
          <ul>
            <li v-for="(item, index) in newsInfo" :key="index">
              <a  href="javascript:;" @click="goToDtl(item.id)" class="ellipsis">{{item.title}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'indNews',
  //  组件
  components: {
  },
  // computed: mapState(['token']),
  props: {
    imgH: {
      type: String,
      default: '266px'
    },
    // minW: {
    //   type: Number,
    //   default: 1200
    // }
  },
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      newsInfo: [
        {
          id: '',
          insertTime: '',
          content: '',
          picurl: '',
          title: ''
        }
      ],
    }
  },
  methods: {
    urlInit (url) {
      if (url) {
        url = url.substr(0, 4) === 'http' ? url : 'http://' + url
        window.open(url, '_blank')
      } else {
      }
    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`)
    },
    getBannerNews () {
      this.$axios({
        method: "get",
        url: "web/newsContent/list",
        params:{
          categoryId: '1612698888290250753',
          pageNum: 1,
          pageSize: 5
        }
      }).then(res=>{
        this.newsInfo = res.data.rows /* 将拿到的值，赋值给data */
        console.log("banner...",this.banner);
      })
    }
  },
  created () {
  },
  mounted () {
    this.getBannerNews();
  }
}
</script>

<style scoped>
.home_news_banner {
  width: 405px;
}

.home_news_banner .goLink {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.home_news_banner .goLink span {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 14px;
  color: #fff;
  text-align: left;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

</style>
