<template>
  <div>
    <!--子菜单头-->
    <webSubHead :selectedMenu="selectedMenu" :headerMenuSelected="headerMenuSelected" :subWebMenuList="subWebMenuList"></webSubHead>
    <div class="content-height" v-if="newsList.length>0" >
      <!--    新闻列表  -->
      <commonNewsList v-if="isBanner===0"  :newsList="newsList"></commonNewsList>
      <!--    banner新闻列表  -->
      <bannerNewsList v-if="isBanner===1" :newsList="newsList"></bannerNewsList>
      <div class="page-info" v-if="newsList.length>0">
        <el-pagination
            @size-change="onPageSizeChange"
            @current-change="onPageChange"
            :current-page="pageNum"
            :page-sizes="[pageSize]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataTotal">
        </el-pagination>
      </div>
    </div>
    <div class="content-height" v-if="newsList.length===0">
<!--      <div class="noContent">暂无{{subWebMenuList[selectedMenu-1].showName}}内容</div>-->
    </div>
    <comFooter></comFooter>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'webNewsList',
  //  父参数
  props:{
    isBanner: {
      type: Number,
      default: 0
    },
    pageNum: {
      type:Number,
      default:1
    },
    pageSize: {
      type:Number,
      default:10
    },
    searchValue: {
      type:String,
      default:undefined
    },
    categoryId: '',
    selectedMenu: '',
    headerMenuSelected:'',
    subWebMenuList:[]
  },
  //  组件
  components: {
    //banner,
    // subWebMenu,
  },
  computed: mapState(['token']),
  data() {
    return {
      // pageNum:'1',
      pageNumEx:this.pageNum,
      pageSizeEx:this.pageSize,
      contentHeight:'600px',
      dataTotal:'0',
      newsList: [],
    }
  },
  methods: {
    onPageSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.pageSizeEx=val;
      this.getNewsList();
    },
    onPageChange(val) {
      //console.log(`当前页: ${val}`);
      this.pageNumEx=val;
      this.getNewsList();
    },
    getNewsList() {
      this.$axios({
        method: "get",
        url: "web/newsContent/list",
        params:{
          "categoryId": this.categoryId,
          "pageSize":this.pageSizeEx,
          "pageNum":this.pageNumEx,
          "searchValue":this.searchValue,
        }
      }).then(res => {
        this.newsList = res.data.rows; /* 将拿到的值，赋值给data */
        this.dataTotal = res.data.total;
        if(this.isBanner===1){
           var height = this.newsList*275 +300;
           this.contentHeight = height+'px';
         }
      })
    }
  },
  mounted() {
    this.getNewsList()
  },
  created() {

  }
}
</script>

<style scoped>
.content-height{
  min-height: 300px;
}
.page-info{
  margin-bottom: 20px;
}
.el-pagination {
  text-align: center;
}
.noContent{
  display: block;
  margin: 0 auto;
  padding-top: 30px;
  /*padding-bottom: 30px;*/
  width: 200px;
  font-size: 18px;
  font-weight: bold;
  font-family: "Microsoft YaHei";
}

</style>
