<template>
  <div>
    <div class="footer">
      <div class="foot_top">
        <div class="container">
          <div class="foot_link clearfix">
            <div class="select fl">
              <p>友情链接</p>
              <ul class="yqlj">
                <li><a href="http://www.nhc.gov.cn/" target=“_blank”>中华人民共和国卫生部</a></li>
                <li><a href="http://www.natcm.gov.cn/" target=“_blank”>国家中医药管理局</a></li>
                <li><a href="https://www.gzsrmyy.com/" target=“_blank”>赣州市人民医院</a></li>
                <li><a href="https://www.gyyfy.com/" target=“_blank”>赣州市附属医院</a></li>
                <li><a href="https://www.gzslyy.com/" target=“_blank”>赣州市立医院</a></li>
                <li><a href="http://www.jxgzsfybjy.cn/" target=“_blank”>赣州市妇幼保健院</a></li>
              </ul>
            </div>
            <!--          <a href="#" class="sina fl"><i></i></a>-->
          </div>
          <div class="foot_nav clearfix">
            <ul>
              <li>医院概况</li>
              <li><a href="javascript:;" @click="clickHeadMenu('introduce_yyjs')">医院介绍</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('introduce_ldbz')">领导班子</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('introduce_yywh')">医院文化</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('introduce_yxyz')">院训院旨</a></li>
            </ul>
            <ul>
              <li>党团建设</li>
              <li><a href="javascript:;" @click="clickHeadMenu('culture_djgz')">党建工作</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('culture_ztjy')">主题教育</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('culture_qtgz')">群团工作</a></li>
            </ul>
            <ul>
              <li>就诊指南</li>
              <li><a href="javascript:;" @click="clickHeadMenu('guide_mzyyy')">名中医预约</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('guide_jtzn')">交通指南</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('guide_mzzn')">门诊指南</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('guide_zyzn')">住院指南</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('guide_jktj')">健康体检</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('guide_yblc')">医保流程</a></li>
            </ul>
            <ul>
              <li>科室中心</li>
              <li><a href="javascript:;" @click="clickHeadMenu('office_ksjs')">科室介绍</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('office_ysjs')">医生介绍</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('office_mzpb')">门诊排班</a></li>
            </ul>
            <ul>
              <li>中医科普</li>
              <li><a href="javascript:;" @click="clickHeadMenu('health_hlzs')">护理知识</a></li>
              <li><a href="javascript:;" @click="clickHeadMenu('health_jkjy')">健康教育</a></li>
            </ul>
            <div class="code-area">
              <div class="code-name">官方公众号</div>
              <div class="qr-code">
                <img :src="allewm">
<!--                <div>-->
<!--                  <img :src="zyyPic">-->
<!--                  <p>章贡区中医院</p>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <img :src="slyyPic">-->
<!--                  <p>沙河卫生院</p>-->
<!--                </div>-->
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="foot_bot">
        <div class="container clearfix">
          <ul style="width: 500px">
            <li>赣州市章贡区中医院 &nbsp版权所有Copyright©2023Inc.All Rights Reserved</li>
            <li>
              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                <img :src="beianPic" style="vertical-align:middle">
                <span >&nbsp赣ICP备 2023005694号-1</span>
              </a>&nbsp;&nbsp;
              <!--              <a style="margin-left: 20px">-->
              <!--                <img :src="gonganPic" style="vertical-align:middle">-->
              <!--                <span >&nbsp赣公网安备: 36070202000062号</span>-->
              <!--              </a>-->
            </li>
          </ul>
          <ul style="width: 400px">
            <li>地址：赣州市章贡区水南街道九曲河路26号</li>
            <li>
              <span>技术支持：<a href="#" target="_blank">江西行音科技有限公司</a></span>
              <span style="margin-left: 80px">总访问量：{{totalCount}}次</span>
            </li>
          </ul>
          <ul style="width:200px">
            <li>
              <img class="fr" :src="sydwPic">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="min_footer">
      <div class="foot_top">
        <div class="container">
          <div class="foot_link clearfix">
            <div class="select fl">
              <p>友情链接</p>
              <ul class="yqlj">
                <li><a href="http://www.nhc.gov.cn/" target=“_blank”>中华人民共和国卫生部</a></li>
                <li><a href="http://www.natcm.gov.cn/" target=“_blank”>国家中医药管理局</a></li>
                <li><a href="https://www.gzsrmyy.com/" target=“_blank”>赣州市人民医院</a></li>
                <li><a href="https://www.gyyfy.com/" target=“_blank”>赣州市附属医院</a></li>
                <li><a href="https://www.gzslyy.com/" target=“_blank”>赣州市立医院</a></li>
                <li><a href="http://www.jxgzsfybjy.cn/" target=“_blank”>赣州市妇幼保健院</a></li>
              </ul>
            </div>
            <!--          <a href="#" class="sina fl"><i></i></a>-->
          </div>
        </div>
      </div>
      <div class="foot_bot">
        <div class="container clearfix">
          <div style="width: 1000px;">赣州市章贡区中医院<img  style="width: 20px;height: 20px;vertical-align:middle;" :src="sydwPic"> &nbsp版权所有Copyright©2023Inc.All Rights Reserved</div>
          <div>
              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                <span >备案信息：&nbsp</span>
                <img :src="beianPic" style="vertical-align:middle">
                <span >&nbsp赣ICP备 2023005694号-1</span>
              </a>&nbsp;&nbsp;
          </div>
          <div style="width: 1000px;">地址：赣州市章贡区水南街道九曲河路26号</div>
          <div style="width: 1000px;">
            <span>技术支持：<a href="#" target="_blank">江西行音科技有限公司</a></span>

          </div>
          <div >总访问量：{{totalCount}}次</div>
          <div>

          </div>
          <div style="margin: 0 auto;">
              <img :src="allewm" >
          </div>
<!--          <div class="code-area " style="display: flex;">-->
<!--&lt;!&ndash;            <div class="code-name">官方公众号</div>&ndash;&gt;-->
<!--            <div class="qr-code " style="width: 100%">-->
<!--              <img :src="allewm">-->
<!--&lt;!&ndash;              <div style="display: inline-block">&ndash;&gt;-->
<!--&lt;!&ndash;                <img :src="zyyPic">&ndash;&gt;-->
<!--&lt;!&ndash;                <p style="color: #403531">章贡区中医院</p>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div style="display: inline-block">&ndash;&gt;-->
<!--&lt;!&ndash;                <img :src="slyyPic">&ndash;&gt;-->
<!--&lt;!&ndash;                <p style="color: #403531">沙河卫生院</p>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default{
  name: 'webFooter',
  props: {
    // minW: {
    //   type: Number,
    //   default: 1200
    // }
  },
  data(){
    return{
      totalCount:100,
      newsDetail:undefined,
      zyyPic: require('../../assets/images/zyy.jpg'),
      slyyPic:require('../../assets/images/slyy.jpg'),
      gonganPic:require('../../assets/images/gongan.png'),
      beianPic:require('../../assets/images/beian.png'),
      sydwPic:require('../../assets/images/sydw.png'),
      allewm:require('../../assets/images/allewm.jpg'),
    }
  },
  methods:{

    //  跳转链接详情
    clickHeadMenu(pathName){
      this.$router.push({ name: pathName});
    },

    // 去对应页面
    gotoList(item){
      this.$router.push(item.path);
    },

//  获取新闻详情
    getNewsDetail(){
      this.$axios({
        method: "get",
        url: "web/newsContent/getWebInfo",
      }).then(res=>{
        this.totalCount = res.data.data.count /* 将拿到的值，赋值给data */
      })
    },
  },
  mounted() {
    this.getNewsDetail();
  }
}
</script>

<style scoped>
.foot_top{
  background-color: var(--common-color);
}

.select:hover .yqlj{
  display: block;
}

/*.ewm>img{*/
/*  display: block;*/
/*}*/
.code-area{
  float:right;
}

.code-name{
  font-size: 16px;
  color: #d3d3d3;
  font-weight: bold;
  line-height: 27px;
  margin-bottom: 7px;
  font-family: "微软雅黑";
}

.qr-code{
  /*display: block;*/
  float: right;
  border-radius: 5px;
}
.qr-code>div{
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display:inline-block;
  background: #ffffff;
}

.qr-code>div>p{
  text-align: center;
  padding-bottom: 10px;
  font-family: "Microsoft YaHei";
}
.qr-code>div>img{
  width: 100px;
  height: 100px;
}
.fwl{
  margin-left: 15px;
}
</style>
