<template>
  <div>
    <model-title :title="title"></model-title>
    <div class="ind_export">
      <div class="container">
        <div class="doctor-type">
          <!--        :style="{ backgroundImage: `url(${zjztBg})` }"-->
          <li :class="{'doctor-item-default': isExport!=1,'doctor-item-selected': isExport===1}"
              href="javascript:;"
              @click="goToWeb('office_zjjs')"
              @mouseenter="onMouseEnterDoctorType(1)">
            专家介绍
          </li>
          <li  :class="{'doctor-item-default': isExport===1,'doctor-item-selected': isExport!=1}"
               href="javascript:;"
               @click="goToWeb('office_ysjs')"
               @mouseenter="onMouseEnterDoctorType(0)">
            医生介绍
          </li>
        </div>
        <div class="export_recomend" v-if="newsInfo.length>4">
          <div class="export-swiper swiper-wrapper swiper-container swiper-container-horizontal">
            <swiper :options="swiperOption">
              <swiper-slide v-for="(item,index) in newsInfo" :key="index">
                <a href="javascript:;" @click="goToDtl(item.id)">
                  <img class="pic imgBg" :src="item.headImg" :alt="item.exportName" :style="{'height': imgH}">
                  <div class="main">
                    <div class="title"><span>{{item.exportName}}</span>{{item.exportTypeName}}</div>
                    <article class="txt" v-html="item.content"></article>
                  </div>
                </a>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <!--            <div class="swiper-button-prev" slot="button-prev"></div>-->
              <!--            <div class="swiper-button-next" slot="button-next"></div>-->
            </swiper>
          </div>
          <div class="swiper-button-next"><i class="next-i"></i></div>
          <div class="swiper-button-prev"><i class="prev-i"></i></div>
        </div>
        <div class="noEnoughData" v-if="newsInfo.length<5">
          <div class="noEnoughData-center">
            <div class="noEnoughData-item" v-for="(item,index) in newsInfo" :key="index">
              <a href="javascript:;" @click="goToDtl(item.id)">
                <img class="noEnoughData-item-img" :src="item.headImg" :alt="item.exportName" :style="{'height': imgH}">
                <div class="noEnoughData-item-main">
                  <div class="noEnoughData-item-title">
                    <span class="noEnoughData-item-title-name">{{item.exportName}}</span>
                    <span class="noEnoughData-item-title-flag">/</span>
                    <span class="noEnoughData-item-title-type">{{item.exportTypeName}}</span></div>
                  <div class="noEnoughData-item-txt" v-html="item.content"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="min_export">
      <div class="export_recomend" v-if="newsInfo.length>0">
        <div class="export-swiper swiper-wrapper swiper-container swiper-container-horizontal">
          <swiper :options="swiperOptionMin">
            <swiper-slide v-for="(item,index) in newsInfo" :key="index">
              <a href="javascript:;" @click="goToDtl(item.id)">
                <img class="pic imgBg" :src="item.headImg" :alt="item.exportName" :style="{'height': imgMinH}">
                <div class="main">
                  <div class="title"><span>{{item.exportName}}</span>{{item.exportTypeName}}</div>
                  <article class="txt" v-html="item.content"></article>
                </div>
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="swiper-button-next"><i class="next-i"></i></div>
        <div class="swiper-button-prev"><i class="prev-i"></i></div>
      </div>
    </div>
  </div>

</template>
<script>

import ModelTitle from "@/components/modelTitle";
export default {
  name: 'indExport',
  //  组件
  components: {
    ModelTitle
  },
  // computed: mapState(['token']),
  props: {
    imgH: {
      type: String,
      default: '320px'
    },
    imgMinH: {
      type: String,
      default: '240px'
    },
  },
  data () {
    return {
      title:"专家介绍",
      leftPic: require('../../assets/images/left.png'),
      rightPic: require('../../assets/images/right.png'),
      ysjsPic: require('../../assets/images/ysjs.png'),
      yyzjPic: require('../../assets/images/yyzj.png'),
      isExport:1,
      swiperOption: {
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        slidesPerView: 5,
        spaceBetween: 20,
        speed:1000,
        loop: true,
        circular: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionMin: {
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        slidesPerView: 2,
        spaceBetween: 20,
        speed:1000,
        loop: true,
        circular: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      newsInfo: [],
    }
  },
  methods: {
    //鼠标移入专家类型
    onMouseEnterDoctorType(webName){
        this.isExport=webName;
        this.getExportList();
    },
    // 跳转新闻详情
    goToWeb(webName) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push({ name: webName});
    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/office/exportInfo/${id}`)
    },
    //  获取专家列表
    getExportList () {
      this.newsInfo=[];
      this.$axios({
        method: "get",
        url: "web/hospitalExport/list",
        params:{
          pageNum: 1,
          pageSize: 10,
          isExport:this.isExport,
          // ?this.isExport:undefined,
        }
      }).then(res=>{
        this.newsInfo = res.data.rows /* 将拿到的值，赋值给data */
        console.log("indExport...",this.newsInfo);
      })
    }
  },
  created () {
    //  获取专家列表
    this.itemSelected='office_zjjs';
    this.getExportList();
  },
  mounted () {
   // this.getBannerNews();
  }
}
</script>

<style scoped>

</style>
