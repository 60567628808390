<template>

<!--    <div class="thediv" ref="thediv" @mouseover="clearFdAd" @mouseout="starFdAd">-->
<!--      <div>广告</div>-->
<!--      <p style="color:red;float:right;font-size:12px;cursor:pointer;" @click="close">关闭</p>-->
      <div :style="{top:topPos+'px',left:leftPos+'px'}" class="ad_float" ref="thediv" @mouseover="clearFdAd" @mouseout="starFdAd">
<!--        style="display: block"-->
        <p style="color:red;float:right;font-size:12px;cursor:pointer;margin-top: -16px;" @click="close">关闭</p>
        <img :src="mzyyyPic">
<!--      <span style="color: red;font-size: 12px;cursor: pointer;" @click="close">关闭</span>-->
      </div>

<!--    </div>-->
</template>
<script>
var interval;
export default {
  data () {
    return {
      mzyyyPic: require('../assets/images/jdhb.png'),
      xPos: 0,
      yPos: 200,
      step: 1,
      delay: 8,
      height: 0,
      Hoffset: 0,
      Woffset: 0,
      yon: 0,
      xon: 0,
      pause: true,
      topPos: 43,
      leftPos: 2,
    }
  },

  mounted () {
    interval = setInterval(this.changePos, 10)
  },
  methods: {
    changePos () {
      // let width = document.documentElement.clientWidth
      // let height = document.documentElement.clientHeight
      // this.Hoffset = this.$refs.thediv.offsetHeight
      // this.Woffset = this.$refs.thediv.offsetWidth

      // this.$refs.thediv.style.left =
      //     this.xPos +
      //     document.body.scrollLeft +
      //     document.documentElement.scrollLeft +
      //     'px'
      // this.$refs.thediv.style.top =
      //     this.yPos +
      //     document.body.scrollTop +
      //     document.documentElement.scrollTop +
      //     'px'

      this.leftPos = this.xPos+document.body.scrollLeft+document.documentElement.scrollLeft;
      this.topPos = this.yPos+document.body.scrollTop+document.documentElement.scrollTop;
    },
    clearFdAd () {
      clearInterval(interval)
    },
    starFdAd () {
      interval = setInterval(this.changePos, 10)
    },
    close(){
      this.$refs.thediv.style.display='none'
    }
  }
}
</script>
<style scoped>
/*!*浮窗广告*!*/
/*.thediv {*/
/*  display: block;*/
/*  justify-content: space-between;*/
/*  z-index: 100;*/
/*  position: absolute;*/
/*  top: 43px;*/
/*  left: 2px;*/
/*  height: 181px;*/
/*  width: 315px;*/
/*  background-color: red;*/
/*}*/
</style>
