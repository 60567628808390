<template>
  <div class="home">
    <ad></ad>
    <!--    网站头部  -->
    <comHeader  :menuSelected="'home'"></comHeader>
    <!--    banner图  -->
    <banner :imgH="'500'" :module="'1'"></banner>
    <!--    菜单  -->
    <indNav></indNav>
    <!--    通知  -->
    <indNotice></indNotice>
<!--    &lt;!&ndash;    新闻  &ndash;&gt;-->
<!--    <indNews></indNews>-->
    <!-- WOW方式新闻-->
    <indWOWNews></indWOWNews>
    <!--    专家介绍  -->
    <indExport></indExport>
<!--    &lt;!&ndash;    科室介绍  &ndash;&gt;-->
<!--    <indOffice></indOffice>-->
    <!--    联系我们  -->
    <indContact></indContact>
    <!--页脚-->
    <comFooter></comFooter>
  </div>
</template>

<script>
import ad from '@/components/ad2.vue'
import banner from '@/components/banner/banner.vue'
import indNav from '@/pages/home/indNav.vue'
import indNotice from "@/pages/home/indNotice";
import indNews from '@/pages/home/indNews.vue'
import indWOWNews from "@/pages/home/indWOWNews";
import indExport from '@/pages/home/indExport.vue'
import indOffice from '@/pages/home/indOffice.vue'
import indContact from '@/pages/home/indContact.vue'

import {mapState} from 'vuex'
export default {
  name: 'home',
  //  组件
  components: {
    ad,
    banner,
    indNav,
    indNotice,
    indNews,
    indWOWNews,
    indExport,
    indOffice,
    indContact,

  },
  computed: mapState(['token']),
  data () {
    return {
      // msg: 'Welcome to Your Vue.js App'
    }
  },
  methods: {

  },
  created () {

  },
  mounted () {
  }
}
</script>

<style scoped>
/*.home{*/
/*  background-color: #eeece6;*/
/*}*/
</style>
