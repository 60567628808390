<template>
  <div>
    <div class="header">
    <div class="head_top">
      <div class="container clearfix">
        <a href="javascript:;" class="fl" @click="clickIndexLogo()">
          <img class="header-logo" src="../../assets/images/logo.png">
        </a>
        <div class="right_top fr">
          <div class="head_search">
            <form class="clearfix" method="get" action="/news/yyxw">
              <input type="text" value="" placeholder="新闻搜索..." class="text" name="keywords" onkeyup="value=value.replace(/[`~!%#$^&amp;*()=|{}':;',\\[\]<>《》/?~！%#￥……&amp;*（）——|{}【】‘；：”“'。，、？\s]/g, '')">
              <input type="submit" value="" class="btn" name="">
              <input type="hidden" name="__hash__" value="6b3d11de47ca5b33514ecd22d2a67425_cef62325e50fc3c5f0dc8af6ba91d10d">
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="head_bot">
      <div class="warpBox">
        <ul class="ul ullist">
          <li class="on">
            <a href="/">首页</a>
          </li>
          <li>
            <a href="javascript:;" @click="clickHeadMenu('introduce_yyjs')">医院概况</a>

            <dl class="dl sublist">
              <dd><a href="javascript:;" @click="clickHeadMenu('introduce_yyjs')">医院介绍</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('introduce_ldbz')">领导班子</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('introduce_yywh')">医院文化</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('introduce_yxyz')">院训院旨</a></dd>
            </dl>
          </li>

          <li>
            <a href="javascript:;" @click="clickHeadMenu('culture_djgz')">党团建设</a>
            <dl class="dl sublist">
              <dd><a href="javascript:;" @click="clickHeadMenu('culture_djgz')">党建工作</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('culture_ztjy')">主题教育</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('culture_qtgz')">群团工作</a></dd>
            </dl>
          </li>

          <li>
            <a href="javascript:;" @click="clickHeadMenu('guide_mzyyy')">就医指南</a>
            <dl class="dl sublist">
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_mzyyy')">名中医预约</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_jtzn')">交通指南</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_mzzn')">门诊指南</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_zyzn')">住院指南</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_jktj')">健康体检</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_yblc')">医保流程</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('guide_yydblfyy')">医药代表来访预约</a></dd>
              <!--            <dd><a href="https://www.gzszgqzyy.cn/statics/laifangyuyue.xlsx">医药代表来访预约</a></dd>-->
            </dl>
          </li>

          <li>
            <a href="javascript:;" @click="clickHeadMenu('office_ksjs')">科室中心</a>
            <dl class="dl sublist">
              <dd><a href="javascript:;" @click="clickHeadMenu('office_ksjs')">科室介绍</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('office_ysjs')">医生介绍</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('office_mzpb')">门诊排班</a></dd>
            </dl>
          </li>

          <li>
            <a href="javascript:;" @click="clickHeadMenu('health_hlzs')">中医药科普</a>
            <dl class="dl sublist">
              <dd><a href="javascript:;" @click="clickHeadMenu('health_hlzs')">护理知识</a></dd>
              <dd><a href="javascript:;" @click="clickHeadMenu('health_jkjy')">健康教育</a></dd>
            </dl>
          </li>
        </ul>
        <div class="clear"></div>
      </div>
    </div>
  </div>
    <div class="m_header">
      <div class="logo" @click="clickIndexLogo()"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'webHeader',
  //  组件
  components: {
  },
  // computed: mapState(['token']),
  props: {
    menuSelected: ''
  },
  data () {
    return {
      logoPic: require("../../assets/images/logo.png"),
      headMenus:[
        {
          showName:"首页",
          menuName:"home",
          pathInfo:{
            name: 'home',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'home',
            }
          }
        },
        {
          showName:"医院概况",
          menuName:"introduce",
          pathInfo:{
            name: 'introduce_yyjs',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'introduce',
            }
          }
        },
        {
          showName:"患者服务",
          menuName:"service",
          pathInfo:{
            name: 'service',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'service',
            }
          }
        },
        {
          showName:"科室特色",
          menuName:"office",
          pathInfo:{
            name: 'office_ksjs',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'office',
            }
          }
        },
        {
          showName:"就诊指南",
          menuName:"guide",
          pathInfo:{
            name: 'guide_mzyyy',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'guide',
            }
          }
        },
        {
          showName:"新闻中心",
          menuName:"news",
          pathInfo: {
            name: 'news',
            params: {
              categoryId: '1612698888290250753',
              selectedMenu: '2',
              headerMenuSelected:'news',
            }
          }
        },
        {
          showName:"医院文化",
          menuName:"culture",
          pathInfo:{
            name: 'culture_djgz',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'culture',
            }
          }
        },
        {
          showName:"健康园地",
          menuName:"health",
          pathInfo:{
            name: 'health',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '2',
              headerMenuSelected:'health',
            }
          }
        },
      ],
    }
  },
  methods: {
    clickIndexLogo()
    {
      this.$router.push({ name: 'home'});
    },
    //  跳转新闻详情
    clickHeadMenu(pathName){
      if(pathName=="guide_yydblfyy")
      {
        this.$router.push(`/news/detail/1701826167925465089`)
      }
      else
      {
        this.$router.push({ name: pathName});
      }

    },
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style scoped>

/*.header{*/
/*  height: 98px;*/
/*  border-bottom-color: #fff;*/
/*}*/




</style>
