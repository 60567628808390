<template>
  <div class="ind_title clear">
    <img class="lfPic" :src="leftPic">
    <span>&nbsp;{{title}}&nbsp;&nbsp;</span>
    <img class="rtPic" :src="rightPic">
  </div>
</template>
<script>

export default {
  name: 'modelTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      leftPic: require('../assets/images/left.png'),
      rightPic: require('../assets/images/right.png'),
    }
  },

  mounted () {

  },
  methods: {
  }
}
</script>
<style scoped>
.ind_title{height: 50px;font-size: 30px;color: #333;line-height: 50px;text-align: center;}
.ind_title .lfPic{margin-left: -20px;margin-bottom: 10px;}
.ind_title .rtPic{margin-left: 150px;margin-bottom: 10px;}
.ind_title span{position: absolute;margin-top: -6px;margin-bottom: 10px;margin-left: 8px;}
</style>
