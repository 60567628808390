<template>
  <div id="amapcontainer" class="map-size">

  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
window._AMapSecurityConfig = {
  securityJsCode: '80d37e714e58ad4cbff39c491738c381' // '「申请的安全密钥」',
}
export default {
  name: 'gaodeMapLocation',
  data () {
    return {
      map: null,
      markerList: [],
      mapList: [
        {
          name: '赣州市章贡区中医院',
          address: '赣州市章贡区中医院地址',
          // lnglats: [114.981855, 25.828954] //  老院地址
          lnglats: [114.922023,25.806522]     //  新院地址
        }
      ]
    }
  },
  mounted () {
    // DOM初始化完成进行地图初始化
    this.initAMap()
  },
  methods: {
    initAMap () {
      AMapLoader.load({
        key: "55bb0dfef8c0cc85a8b55630ea58fd5d", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
          'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        // 获取到作为地图容器的DOM元素，创建地图实例
        this.map = new AMap.Map("amapcontainer", { //设置地图容器id
          resizeEnable: true,
          zoom: this.zoom, // 地图显示的缩放级别
          viewMode: "3D", // 使用3D视图
          zoomEnable: true, // 地图是否可缩放，默认值为true
          dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
          doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
          zoom: 11, //初始化地图级别
          // center: [114.980755, 25.828954], // 初始化中心点坐标 广州
          center: [114.922023,25.806522]
          // mapStyle: "amap://styles/darkblue", // 设置颜色底层

        })
        this.setMapMarker()
      }).catch(e => {
        console.log(e)
      })
    },
    // 增加点标记
    setMapMarker () {
      // 创建 AMap.Icon 实例
      let icon = new AMap.Icon({
        size: new AMap.Size(36, 36), // 图标尺寸
        image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png", // Icon的图像
        imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
        imageOffset: new AMap.Pixel(0, 0)  // 图像相对展示区域的偏移量，适于雪碧图等
      });
      let makerList = []
      this.mapList.forEach((item) => {
        // 遍历生成多个标记点
        let marker = new AMap.Marker({
          map: this.map,
          zIndex: 9999999,
          icon: icon, // 添加 Icon 实例
          offset: new AMap.Pixel(0, 0), //icon中心点的偏移量
          position: item.lnglats // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        makerList.push(marker)
      });
      this.map.add(makerList)
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    }
  }
}
</script>

<style lang="less">

</style>
