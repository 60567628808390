<template>
  <div>
    <!--带banner图新闻列表-->
    <div class="news_main">
      <div class="container">
        <ul class="news_list">
          <li v-for="(item, index) in newsList" :key="index">
            <a class="clearfix" href="javascript:;" @click="goToDtl(item.id)">
              <img class="pic imgBg" :src="item.picurl" :alt="item.title">
              <div class="main">
                <div class="title ellipsis">{{ item.title }}<i class="motion"></i></div>
                <div class="date">{{item.createTime}}</div>
                <div class="txt">
                  <article v-html="matchReg(item.content)"></article>
                </div>
                <div class="tips"><i></i>{{item.categoryName}}</div>
              </div>
            </a>
          </li>
        </ul>
        <!--      <div class="page">-->
        <!--        <div class="page_con clearfix">-->
        <!--          &nbsp;<a href="http://www.hztcm.net/news/index/p/1.html" class="num on">1</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/2.html" class="num">2</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/3.html" class="num">3</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/4.html" class="num">4</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/5.html" class="num">5</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/6.html" class="num">6</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/7.html" class="num">7</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/8.html" class="num">8</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/9.html" class="num">9</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/10.html" class="num">10</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/248.html" class="num">..248</a>&nbsp;<a href="http://www.hztcm.net/news/index/p/2.html" class="num">&gt;</a>				</div>-->
        <!--      </div>-->
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "bannerNewsList",
  props: {
    newsList: [],
  },
  methods:{
    matchReg(str){
      let reg=/<\/?.+?\/?>/g;
      return str.replace(reg,'');
    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`)
    },
  }
}
</script>

<style scoped>

</style>
