<template>
  <div>
    <!--    网站头部  -->
    <comHeader :menuSelected="headerMenuSelected"></comHeader>
    <!--    banner图  -->
    <banner :imgH="'500'" :module="'1'"></banner>
    <!--    子菜单  -->
    <subWebMenu :subWebMenu="subWebMenuList" :menuSelected="selectedMenu"></subWebMenu>
  </div>
</template>
<script>
import banner from '@/components/banner/banner.vue'
import {mapState} from 'vuex'
export default {
  name: 'webSubHead',
  //  父参数
  props:{
    //categoryId: '',
    selectedMenu: '',
    headerMenuSelected:'',
    subWebMenuList:[]
  },
  //  组件
  components: {
    banner,
    // subWebMenu,
  },
  computed: mapState(['token']),
  data() {
    return {
     // newsList: [],

    }
  },
  methods: {
    // getNewsList() {
    //   this.$axios({
    //     method: "get",
    //     url: "web/newsContent/list",
    //     params:{
    //       //"categoryId": "1612699255010832386"
    //       "categoryId": this.categoryId
    //     }
    //   }).then(res => {
    //     this.newsList = res.data.rows /* 将拿到的值，赋值给data */
    //     console.log("招标采购...", this.newsList);
    //   })
    // }
  },
  mounted() {
    // this.getNewsList()
  },
  created() {

  }
}
</script>

<style scoped>

</style>
