<template>
  <div class="notice_main">
    <div class="container">
      <div class="notice_list">
        <li  v-for="(item, index) in newsList" :key="index">
          <a class="ellipsis">
            <div @click="goToDtl(item.id)">{{item.title}}<span class="fr">{{item.createTime}}</span></div>
          </a>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonNewsList",
  props: {
    newsList: [],
  },
  methods:{
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`)
    },
  }
}
</script>

<style scoped>

</style>
