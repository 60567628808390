import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "babel-polyfill"

//  引入css
// import '../static/css/style.css'
// import '../static/css/main.css'
import '../static/css/base.css'
import '../static/css/common.css'
//import '../src/assets/css/swiper.min.css'

// 全局变量监听
Vue.prototype.$event = new Vue()

//  全局网站头部、底部
import comHeader from './pages/webComponents/header'
Vue.component("comHeader",comHeader)
import comFooter from './pages/webComponents/footer'
Vue.component("comFooter",comFooter)

//  全局引入子菜单
import subWebMenu from "@/components/subWebMenu";
Vue.component("subWebMenu",subWebMenu)
import commonNewsList from "@/components/commonNewsList";
Vue.component("commonNewsList",commonNewsList)
import webNewsList from "@/pages/webComponents/webNewsList";
Vue.component("webNewsList",webNewsList)
//  全局子菜单头（头，banner，菜单）
import webSubHead from "@/pages/webComponents/webSubHead";
Vue.component("webSubHead",webSubHead)
//  banner新闻列表
import bannerNewsList from "@/components/bannerNewsList";
Vue.component("bannerNewsList",bannerNewsList)
//  全局引入渲染url
import urlHtmlPanel from "@/components/htmlPanel";
Vue.component("urlHtmlPanel",urlHtmlPanel)

// swiper
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

//  引入quill
//富文本
import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// //  引入WOW插件
// import 'animate.css'
// import wow from 'wowjs'
// Vue.prototype.$wow = wow

// api
import api from './config/api.js'
Vue.prototype.$app = api

// axios
import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'  //关键代码
axios.defaults.withCredentials = true

// 全局引入百度地图插件
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'YaL3uFpn236BbuFboAlY1Be83gCKnxW1'
})
// 全局引入vue-jsonp
import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)

import AMap from 'vue-amap';
Vue.use(AMap);

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '55bb0dfef8c0cc85a8b55630ea58fd5d',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation']
  //AMap.Geolocation定位服务插件。融合了浏览器定位、高精度IP定位、安卓定位sdk辅助定位等多种            手段，提供了获取当前准确位置、获取当前城市信息、持续定位(浏览器定位)等功能。
});
// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'element-ui/lib/theme-chalk/fonts/element-icons.ttf';
// import 'element-ui/lib/theme-chalk/fonts/element-icons.woff';
Vue.use(ElementUI);



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  // 预渲染，这个注意加上，要不然不会执行预编译的
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
