<template>
  <div>
<!--  <div class="head">-->
    <div class="ind_news">
<!--      <section>-->
<!--        class="wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;" target="_blank"-->
        <img class="xwzx" :src="xwzxPic">
        <ul class="news_type_ul">
          <li  class="lf" :class="{'news_type_on': item.categoryName === selectNewsCategory.categoryName}" v-for="(item, index) in newsCategoryList" :key="index">
              <a href="javascript:;" @click="getNewsList(item)">
                {{item.showName}}
              </a>
          </li>
        </ul>
        <a class="news_more" href="javascript:;" @click="goToNewsCategory(selectNewsCategory)">更多+</a>
        <div class="news_box">
          <div class="news_left lf">
<!--            <el-carousel indicator-position="outside" :height="dataHeight" >-->
<!--              <el-carousel-item v-for="(item,index) in bannerNewsList" :key="index">-->
<!--                <a href="javascript:;" @click="goToDtl(item.id)">-->
<!--                  <img class="imgBg" :src="item.picurl" :alt="item.title">-->
<!--                  <span class="lbtext">{{item.title}}</span>-->
<!--                </a>-->
<!--              </el-carousel-item>-->
<!--            </el-carousel>-->

            <swiper class="news-swiper" :options="swiperOption">
              <swiper-slide v-for="(item,index) in bannerNewsList" :key="index">
                <a href="javascript:;" @click="goToDtl(item.id)">
                  <img :src="item.picurl" :alt="item.title">
                  <p class="ellipsis">{{item.title}}</p>
                </a>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>

<!--              <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--              <div class="swiper-button-next" slot="button-next"></div>-->
            </swiper>
          </div>
          <div class="news_right According rf">
            <section v-if="newsList.length>0">
              <ul>
                <li v-for="(item, index) in newsList" :key="index">
                  <div class="date lf">
                    <p>{{getNewsDay(item,1)}}</p>
                    <span>{{getNewsDay(item,0)}}</span>
                  </div>
                  <div class="content lf">
                    <p>
                      <a href="javascript:;" @click="goToDtl(item.id)">{{item.title}}</a>
                    </p>
                  </div>
                </li>
              </ul>
            </section>
            <section v-if="newsList.length==0">
<!--              <div class="noContent">暂无{{selectNewsCategory.showName}}内容</div>-->
            </section>
          </div>
        </div>
<!--      </section>-->
    </div>
    <div class="ind_news_min">

      <div class="news_left lf">
        <!--            <el-carousel indicator-position="outside" :height="dataHeight" >-->
        <!--              <el-carousel-item v-for="(item,index) in bannerNewsList" :key="index">-->
        <!--                <a href="javascript:;" @click="goToDtl(item.id)">-->
        <!--                  <img class="imgBg" :src="item.picurl" :alt="item.title">-->
        <!--                  <span class="lbtext">{{item.title}}</span>-->
        <!--                </a>-->
        <!--              </el-carousel-item>-->
        <!--            </el-carousel>-->

        <swiper class="news-swiper" :options="swiperOptionMobile">
          <swiper-slide v-for="(item,index) in bannerNewsList" :key="index">
            <a href="javascript:;" @click="goToDtl(item.id)">
              <img :src="item.picurl" :alt="item.title">
              <p class="ellipsis">{{item.title}}</p>
            </a>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
<!--      <MinNewsTitle :list="this.newsList" :title="this.minNewsTitle" :news-catetory="this.minNewsCategory"></MinNewsTitle>-->
<!--      <MinNewsTitle :category-list="newsCategoryList"></MinNewsTitle>-->
      <MinNews :newsInfoList="this.newsCategoryList" :page-size="4"></MinNews>
    </div>

<!--  </div>-->
<!--    <div class="ind_news_min">-->
<!--      <div class="container">-->
<!--        <model-title :title="title_min"></model-title>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import MinNews from "@/components/minNews";
import MinNewsTitle from "@/components/minNewsTitle";
export default {
  name: 'indWOWNews',
  //  组件
  components: {
    MinNews,
    MinNewsTitle
  },
  // computed: mapState(['token']),
  props: {
    imgH: {
      type: String,
      default: '266px'
    },
    dataHeight: {
      type: String,
      default: '375px'
    }
  },
  data () {
    return {
      minNewsTitle:["医院新闻","媒体聚焦","招标采购"],
      minNewsCategory:"news_yyxw",
      xwzxPic: require('../../assets/images/xwzx.png'),
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionMobile: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      bannerNewsList: [],
      newsList:[],
      selectNewsCategory:{},
      newsCategoryList:[
        {
          showName: "医院新闻",
          categoryName: "news_yyxw",
          categoryId:'1612698888290250753',
        },
        {
          showName: "招标采购",
          categoryName:"news_zbcg",
          categoryId:'1612699255010832386',
        },
        {
          showName: "媒体聚焦",
          categoryName:"news_mtjj",
          categoryId:'1612699062337089538',
        },
        {
          showName: "行业新闻",
          categoryName:"news_hyxw",
          categoryId:'1612698946733682689',
        },
      ],
    }
  },
  methods: {

    //  获取新闻日期
    getNewsDay(newsInfo,type){
      if(newsInfo.createTime!==undefined){
        if(type===1){
          return newsInfo.createTime.substring(8,10);
        }
        else
        {
          return newsInfo.createTime.substring(0,7);
        }
      }
    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`);
    },
    // 跳转新闻详情
    goToNewsCategory(item) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push({ name: item.categoryName});
    },
    //  获取banner新闻列表
    getBannerNews () {
      this.$axios({
        method: "get",
        url: "web/newsContent/list",
        params:{
          categoryId: '1612698888290250753',
          pageNum: 1,
          pageSize: 5
        }
      }).then(res=>{
        this.bannerNewsList = res.data.rows /* 将拿到的值，赋值给data */
        console.log("banner...",this.banner);
      })
    },
    //  获取新闻列表
    getNewsList(newsInfo) {
      this.newsList=[];
      this.selectNewsCategory = newsInfo;
      this.$axios({
        method: "get",
        url: "web/newsContent/list",
        params:{
          categoryId: newsInfo.categoryId,
          pageNum:1,
          pageSize:4
        }
      }).then(res => {
        this.newsList = res.data.rows /* 将拿到的值，赋值给data */
        console.log("招标采购...", this.newsList);
      })
    }
  },
  created () {
  },
  mounted () {
    //  获取banner新闻
    this.getBannerNews();
    //  获取新闻
    this.getNewsList(this.newsCategoryList[0]);
  }
}
</script>

<style scoped>

/* ä¸­é—´æé†’è½®æ’­ */
.ind_news{
  width: 1200px;
  height: 550px;
  margin: 50px auto;
  position: relative;
}

.ind_news a{text-decoration: none;color: #000;}

.ind_news .xwzx{
  display: block;
  margin: 0 auto;
}

.ind_news .news_more{
  display: block;
  width: 96px;;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background: #E1F2FD;
  position: absolute;
  right: 0;
  top: 75px;
}
.ind_news .news_more:hover{
  transform: scale(1.2);
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
}

.ind_news .news_type_ul{
  width: 448px;
  height: 34px;
  margin: 20px auto 0;
}
.ind_news .news_type_ul li{
  padding: 6px 23px;
  color: var(--common-color);
  cursor: pointer ;
  font-size: 16px;
}
.ind_news .news_type_ul>li:hover{
  background-color: var(--common-color);
  transform: scale(1.2);
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
}

.ind_news .news_type_on{
  background: var(--common-color);
  /*color: #fff !important;*/
}

.ind_news .news_type_on a{
  color: #fff;
}

.ind_news .news_type_ul>li>a:hover{
  color: #fff;
}


/*.imgBg{*/
/*  background-size: cover;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.lbtext{*/
/*  position: absolute;*/
/*  !*top: 0;*!*/
/*  right: 0;*/
/*  bottom: 0px;*/
/*  font-size: 14px;*/
/*  width: 100%;*/
/*  height: 34px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  cursor: pointer;*/
/*  background-color: var(--common-color);*/
/*  color: #ffffff;*/
/*}*/



.ind_news .news_box{
  width: 1200px;
  height: 405px;
  margin-top: 36px;
}
.ind_news .news_left{
  width: 475px;
  height: 100%;
}

.ind_news .news_right{
  width: 705px;
  height: 100%;
  /*display: none;*/
}

.ind_news .news_right li{
  width: 705px;
  height: 99px;
}

.ind_news .date{
  height: 67px;
  width: 83px;
  border-right: 1px solid #E7E7E7;
  margin-top: 15px;
}
.ind_news .date p{
  margin-top: 7px;
  font-size: 25px;
  padding-left: 42px;
}

.ind_news .date span{
  display: block;
  margin-left: 16px;
  font-size: 14px;
  color: #949494;
}
.ind_news .content{
  width: 596px;
  height: 67px;
  border-left: 1px solid #949494;
  margin-top: 15px;
  padding-left: 20px;
}
.ind_news .content>p>a{
  font-size: 18px;
  font-weight: bold;
}

.ind_news .content>p>a:hover {
  text-decoration: underline;
}

/*.noContent{*/
/*  margin-top: 7px;*/
/*  font-size: 18px;*/
/*  font-weight: bold;*/
/*  font-family: "Microsoft YaHei";*/
/*}*/


</style>
