<template>
  <div> <p v-html="html"></p></div>
</template>
<script>
export default {
  name: 'urlHtmlPanel',
  props: {
    url: {
      required: true
    }
  },
  data() {return {html: ""};},
  watch: {url(value) {this.load(value);}},
  mounted() {this.load(this.url);},
  methods: {
    load(url) {
      if (url && url.length > 0) {
        let param = {accept: "text/html, text/plain"};
        // this.$http.get(url, param).then(response => { this.html = response.data;}).catch(() => {this.html = "加载失败";});
        this.$axios.get(url, param).then(response => { this.html = response.data;}).catch(() => {this.html = "加载失败";});
      }
    }
  }
};
</script>
<style scoped>

</style>
