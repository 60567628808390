import { render, staticRenderFns } from "./indOffice.vue?vue&type=template&id=33138076&scoped=true&"
import script from "./indOffice.vue?vue&type=script&lang=js&"
export * from "./indOffice.vue?vue&type=script&lang=js&"
import style0 from "./indOffice.vue?vue&type=style&index=0&id=33138076&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33138076",
  null
  
)

export default component.exports