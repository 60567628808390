<template>
  <div>
    <baidu-map
        style="height: 400px"
        class="map"
        :zoom="zoom"
        :center="center"
        :scroll-wheel-zoom="true"
    >
      <!-- BMAP_NORMAL_MAP:展示图片街道视图, BMAP_HYBRID_MAP:卫星和路网的混合视图-->
      <bm-map-type
          :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
      ></bm-map-type>
      <!-- BMAP_ANIMATION_BOUNCE:定位点跳动 -->
      <bm-marker
          :position="center"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
      >
<!--        &lt;!&ndash; 时间文本 &ndash;&gt;-->
<!--        <bm-label-->
<!--            :content="time"-->
<!--            :labelStyle="{-->
<!--                    color: 'black',-->
<!--                    fontSize: '12px',-->
<!--                    borderColor: '#fff',-->
<!--                    borderRadius: 10-->
<!--                  }"-->
<!--            :offset="{ width: -48, height: 28 }"-->
<!--        />-->
      </bm-marker>
    </baidu-map>
  </div>

</template>

<script>
// let timeId;
export default {
  name: 'baiduMapLocation',
  data() {
    return {
      // center: "",
      // locations: {
      //   // 红色标点的经纬度需要设一个初始值，不然地图无法渲染处理
      //   lng: 114.988522,
      //   lat: 25.834868,
      // },
      // zoom: 3,
      // addressVal: "赣州市章贡区中医院",
      dialogMap: false,//控制弹窗
      time: '',//定位点下的时间
      center: { lng: 114.988522, lat: 25.834868 }, //坐标,
      zoom: 18,//缩放
      sign: false//弹窗开关
    };
  },
  created() {
  //  this.geoTest();
  },
  methods: {
    handler({ BMap, map }) {
      console.log(55, BMap, map);
      this.center = "";
      // this.zoom = 18;
      // 获取IP地址的经纬度，详情查看官方文档：
      // https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_webgl_1_0.html#a8b42
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition((res) => {
        // IP地址赋值给locations对象
        this.locations.lng = res.point.lng;
        this.locations.lat = res.point.lat;
      });
    },
    locationSuccess() {
      console.log(`定位成功`);
    },
    async geoTest() {
      // 调用百度地图API,根据地址获取经纬度
      await this.$jsonp("http://api.map.baidu.com/geocoding/v3/", {
        address: this.addressVal, // input框输入的地址
        output: "json",
        ak: "YaL3uFpn236BbuFboAlY1Be83gCKnxW1", // 你的AK秘钥
      })
          .then((json) => {
            console.log(`json success:`, json);
            this.locations = json.result.location;
          })
          .catch((err) => {
            // clearTimeout(timeId);
            // if (err) {
            //   timeId = setTimeout(() => {
            //     this.geoTest();
            //   }, 2000);
            // }
            console.log(`json err:`, err);
          });
    },
    // 修改地址
    btn() {
      this.geoTest();
    },
  },
};
</script>

<style scoped>
.box_map {
  margin: 30px auto;
  width: 1000px;
  height: 600px;
  background-color: #f0f;
  position: relative;
}
.inputVal {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
}

input {
  width: 60%;
}
button {
  margin-top: 10px;
  margin-left: 20px;
  width: 40px;
  height: 30px;
}
</style>
