<template>
  <div class="about_nav">
    <div class="container">
      <ul class="clearfix">
        <li :class="{'on': item.menuName == menuSelected}" v-for="(item, index) in subWebMenu" :key="index">
          <router-link :to="item.pathInfo">{{item.showName}}</router-link>
<!--          <a class="clearfix" href="javascript:;" @click="goToDtl(item)">-->
<!--            {{item.showName}}-->
<!--          </a>-->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'subWebMenu',
  //  组件
  components: {
  },
  // computed: mapState(['token']),
  props: {
    menuSelected: '',
    subWebMenu: [],
  },
  data () {
    return {

    }
  },
  methods: {
    // 跳转新闻详情
    goToDtl(item) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')

     // this.$router.push(`/news/detail/${id}`)
      this.$router.push({name:item.name,params:item.params});
    },
  },
  created () {
    console.log("菜单。。。",this.subWebMenu);
    console.log("menuSelected...",this.menuSelected);
    console.log("选择的菜单...",this.subWebMenu[this.menuSelected-1].showName);
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
