<template>
  <div>
    <!--常规新闻列表-->
    <webNewsList :subWebMenuList="subWebMenuList" :categoryId="categoryId" :selectedMenu="selectedMenu" :headerMenuSelected="headerMenuSelected"></webNewsList>
  </div>
</template>
<script>
import banner from '@/components/banner/banner.vue'
import {mapState} from 'vuex'
export default {
  name: 'news',
  // //  父参数
  // props:{
  //   categoryId: "",
  //   selectedMenu: ""
  // },
  //  组件
  components: {
    banner,
    // subWebMenu,
  },
  computed: mapState(['token']),
  data() {
    return {
      categoryId: this.$route.params.categoryId,
      selectedMenu: this.$route.params.selectedMenu,
      headerMenuSelected: this.$route.params.headerMenuSelected,
      subWebMenuList: [
        {
          showName: "通知公告",
          menuName: "1",
          pathInfo:{
            name: 'news',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '1',
              headerMenuSelected:'news',
            }
          }
        },
        {
          showName: "新闻动态",
          menuName: "2",
          pathInfo:{
            name: 'news',
            params: {
              categoryId: '1612698888290250753',
              selectedMenu: '2',
              headerMenuSelected:'news',
            }
          }
        },
        {
          showName: "院务公开",
          menuName: "3",
          pathInfo:{
            name: 'news',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '3',
              headerMenuSelected:'news',
            }
          }
        },
        {
          showName: "媒体视角",
          menuName: "4",
          pathInfo:{
            name: 'news',
            params: {
              categoryId: '1612699062337089538',
              selectedMenu: '4',
              headerMenuSelected:'news',
            }
          }
        },
        {
          showName: "招标采购",
          menuName: "5",
          pathInfo:{
            name: 'news',
            params: {
              categoryId: '1612699255010832386',
              selectedMenu: '5',
              headerMenuSelected:'news',
            }
          }
        },
      ],
    }
  },
  methods: {
    // getNewsList() {
    //   this.$axios({
    //     method: "get",
    //     url: "web/newsContent/list",
    //     params:{
    //       //"categoryId": "1612699255010832386"
    //       "categoryId": this.categoryId
    //     }
    //   }).then(res => {
    //     this.newsList = res.data.rows /* 将拿到的值，赋值给data */
    //     console.log("招标采购...", this.newsList);
    //   })
    // }
  },
  mounted() {
    //this.getNewsList()
  },
  created() {

  }
}
</script>

<style scoped>

</style>
