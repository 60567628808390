<template>
<!--  <div>-->
<!--    <baidu-map-location></baidu-map-location>-->
<!--  </div>-->
  <div>
    <div class="ind_contact">
      <div class="container">
        <model-title :title="title"></model-title>
        <div class="main ">
          <div class="addr-map">
            <!--        <baidu-map-location></baidu-map-location>-->
            <gaode-map-location></gaode-map-location>
          </div>
          <div class="addr-info">
            <p>地址：章贡区水南街道九曲河路26号</p>
            <p>公交：乘51路、127路、152路可达</p>
            <p>咨询电话：0797-8186735</p>
            <p>急诊电话：0797-8186032</p>
            <p>投诉电话：0797-8186225</p>
            <!--    clearfix    <p>手机：15387846678</p>-->
          </div>
        </div>
      </div>
    </div>
<!--    <div class="min_contact">-->
<!--      <div class="container">-->
<!--        <model-title :title="title"></model-title>-->
<!--        <div class="main ">-->
<!--          <div class="addr-map">-->
<!--            &lt;!&ndash;        <baidu-map-location></baidu-map-location>&ndash;&gt;-->
<!--            <gaode-map-location></gaode-map-location>-->
<!--          </div>-->
<!--          <div class="addr-info">-->
<!--            <p>地址：章贡区水南街道九曲河路26号</p>-->
<!--            <p>公交：乘51路、127路、152路可达</p>-->
<!--            <p>咨询电话：0797-8186735</p>-->
<!--            <p>急诊电话：0797-8186032</p>-->
<!--            <p>投诉电话：0797-8186225</p>-->
<!--            &lt;!&ndash;    clearfix    <p>手机：15387846678</p>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>



</template>
<script>
import BaiduMapLocation from "@/pages/webComponents/baiduMapLocation";
import GaodeMapLocation from "@/pages/webComponents/gaodeMapLocation";
import ModelTitle from "@/components/modelTitle";
export default {
  name: 'indContact',
  //  组件
  components: {
    ModelTitle,
    BaiduMapLocation,
    GaodeMapLocation
  },
  // computed: mapState(['token']),
  props: {
    // minW: {
    //   type: Number,
    //   default: 1200
    // }
  },
  data () {
    return {
      title:"联系我们",
      leftPic: require('../../assets/images/left.png'),
      rightPic: require('../../assets/images/right.png'),
      addressPic: require('../../assets/images/address.png'),
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
