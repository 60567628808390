<template>
  <div>
    <!--子菜单头-->
    <webSubHead :selectedMenu="'1'" :headerMenuSelected="'health'" :subWebMenuList="subWebMenu"></webSubHead>
  </div>
</template>
<script>
import banner from '@/components/banner/banner.vue'
import {mapState} from 'vuex'
export default {
  name: 'health',
  //  组件
  components: {
    banner,
  },
  computed: mapState(['token']),
  data () {
    return {
      subWebMenu: [
        {
          showName:"护理知识",
          menuName:"1",
          pathInfo:{
            name: 'health',
            params: {
              categoryId: '1612712647113134082',
              selectedMenu: '1',
              headerMenuSelected:'health',
            }
          }
        },
        {
          showName:"健康教育",
          menuName:"2",
          pathInfo:{
            name: 'health',
            params: {
              categoryId: '1612712685461655553',
              selectedMenu: '1',
              headerMenuSelected:'health',
            }
          }
        },
      ],
    }
  },
  methods: {

  },
  created () {

  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
