<template>
<!--  <div id="app">-->
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--    <router-view/>-->
  <router-view :key="$route.fullPath"></router-view>
<!--  </div>-->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import axios from 'axios'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      data:""
    };
  },
  /* 第四步 在create钩子函数中 将name事件在页面出现的时候执行 */
  created() {
    this.name();
  },
  methods: {
    /* 第三步 写一个name事件  */
    name() {
      // axios({
      //   method: "get",
      //   url: "api/web/newsBanner/list",
      // }).then(res=>{
      //   this.data = res.data /* 将拿到的值，赋值给data */
      // })
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
