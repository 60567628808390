<template>
  <div>
    <div class="min_news fl" v-if="category.length>0">
      <div class="news_top clearfix">
<!--        <span class="news_top_title fl"  v-for="(item, index) in title" :key="index"><div style="width: 100px;" @click="gotoCategoryNew()">{{item}}</div></span>-->

        <span class="news_top_title fl"  v-for="(item, index) in category" :key="index"><div style="width:78px;" @click="gotoCategoryNew(item)">{{item.showName}}</div></span>
<!--        <ul class="clearfix">-->
<!--          <li :class="{'on': item.menuName == menuSelected}" v-for="(item, index) in subWebMenu" :key="index">-->
<!--            <router-link :to="item.pathInfo">{{item.showName}}</router-link>-->
<!--            &lt;!&ndash;          <a class="clearfix" href="javascript:;" @click="goToDtl(item)">&ndash;&gt;-->
<!--            &lt;!&ndash;            {{item.showName}}&ndash;&gt;-->
<!--            &lt;!&ndash;          </a>&ndash;&gt;-->
<!--          </li>-->
<!--        </ul>-->
        <a class="news_top_more fr" @click="goToMoreNews()">More+</a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'minNewsTitle',
  props: {
    CategoryList:{
      type:Array,
      default:{

      }
    }
    // title:{
    //   type: Array,
    //   default:[]
    // },
    // list: {
    //   type: Array,
    //   default: []
    // },
    // newsCatetory:{
    //   type: String,
    //   default: "news_yyxw"
    // }
  },
  data () {
    return {
      category:this.CategoryList,
    }
  },

  mounted () {

  },
  methods: {
    //
    gotoCategoryNew(item)
    {

    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`)
    },
    // 获取月日
    getMonthDay(date) {
      var monthDay ="";
      if(date.length>10)
        monthDay=date.substring(5,10);
      return monthDay;
    },
    // 跳转更多新闻详情
    goToMoreNews() {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push({ name: this.category});
    },
  }
}
</script>
<style scoped>
  .min_news{
    display: block;
    float: left;
    margin: 0 0 0 0.3rem;
    width: 100%;
  }
  .min_news .news_top{
    height: 30px;
    border-bottom: 1px solid #e6e6e6;
  }
  .min_news .news_top_title{
    height: 29px;
    font-weight: bold;
    font-size: 16px;
    color: var(--common-color);
    /*border-bottom: 2px solid var(--common-color);*/
    /*border-bottom-width: 2px;*/
  }
  .min_news .news_top_more{
    padding-right: 5px;
    height: 39px;
    font-size: 13px;
    color: var(--common-color);;
  }
  .min_news .min_news_list ul li a{
    display: block;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    background: url("../../static/images/spot.png") no-repeat left center;
  }

</style>
