<template>
  <div>
    <div class="min_news fl" v-if="categoryList.length>0">
      <div class="news_top clearfix">
<!--        <span class="news_top_title fl">{{title}}</span>-->
<!--        <a class="news_top_more fr" @click="goToMoreNews()">More+</a>-->

        <span class="news_top_title fl"  style="width: 78px;" v-for="(item, index) in categoryList" :key="index">
          <span :class="{'news_type_on': item.categoryName === selectNewsCategory.categoryName}" @click="gotoCategoryNew(item)">{{item.showName}}</span>
        </span>
        <a class="news_top_more fr" @click="goToMoreNews()">More+</a>
      </div>
      <div class="min_news_list">
        <ul>
          <li v-for="item in newsList">
            <a class="clearfix" @click="goToDtl(item.id)">
                <div class="min_news_title ellipsis">{{item.title}}</div>
                <i class="min_news_hot"></i>
                <div class="min_news_date">{{getMonthDay(item.createTime)}}</div>
            </a>
          </li>
        </ul>
      </div>
<!--        <div class="notice_text">-->
<!--          <el-carousel height="22px" direction="vertical" indicator-position="none" :autoplay="true">-->
<!--            <el-carousel-item v-for="item in list" :key="item.id">-->
<!--              <a href="javascript:;" @click="goToDtl(item.id)">{{item.title}}</a>-->
<!--            </el-carousel-item>-->
<!--          </el-carousel>-->
<!--        </div>-->
    </div>
  </div>
</template>
<script>

export default {
  name: 'min_news',
  props: {
    newsInfoList:{
      type:Array,
      default:[]
    },
    pageSize:{
      type:Number,
      default:3
    }

    // title:{
    //   type: Array,
    //   default:[]
    // },
    // list: {
    //   type: Array,
    //   default: []
    // },
    // newsCatetory:{
    //   type: String,
    //   default: "news_yyxw"
    // }
  },
  data () {
    return {
      categoryList:this.newsInfoList,
      newsList:[],
      selectNewsCategory:{},
      pageSize:this.pageSize
    }
  },

  mounted () {
    if(this.categoryList.length>0)
    {
      this.gotoCategoryNew(this.categoryList[0]);
    }
  },
  methods: {
    //
    gotoCategoryNew(item)
    {
      this.newsList=[];
      this.selectNewsCategory = item;
      this.$axios({
        method: "get",
        url: "web/newsContent/list",
        params:{
          categoryId: item.categoryId,
          pageNum:1,
          pageSize:this.pageSize
        }
      }).then(res => {
        this.newsList = res.data.rows /* 将拿到的值，赋值给data */
        // console.log("招标采购...", this.newsList);
      })
    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`)
    },
    // 获取月日
    getMonthDay(date) {
      var monthDay ="";
      if(date.length>10)
        monthDay=date.substring(5,10);
      return monthDay;
    },
    // 跳转更多新闻详情
    goToMoreNews() {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push({ name: this.selectNewsCategory.categoryName});
    },
  }
}
</script>
<style scoped>
  .min_news{
    display: block;
    float: left;
    margin: 0 0 0 0.3rem;
    width: 100%;
  }
  .min_news .news_top{
    height: 30px;
    border-bottom: 1px solid #e6e6e6;
  }
  .min_news .news_type_on{
    /*background: var(--common-color);*/
    /*color: #fff !important;*/
    border-bottom: 2px solid var(--common-color);
    padding-bottom: 8px;
  }
  .min_news .news_top_title{
    height: 29px;
    font-weight: bold;
    font-size: 16px;
    color: var(--common-color);
    /*border-bottom: 2px solid var(--common-color);*/
  }
  .min_news .news_top_more{
    padding-right: 5px;
    height: 39px;
    font-size: 13px;
    color: var(--common-color);;
  }
  .min_news .min_news_list ul li a{
    display: block;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    background: url("../../static/images/spot.png") no-repeat left center;
  }
  .min_news_title{
    width: calc(100% - 70px);
    float: left;
    font-size: 14px;
    color: #666;
    max-width: 345px;
    margin-top: 5px;
  }
  .min_news_hot{
    display: block;
    float: left;
    width: 12px;
    height: 14px;
    background: url("../../static/images/hot.png") no-repeat left center;
    /*background-position: 0 -120px;*/
    margin: 11px 0 0 0px;
  }
  .min_news_date{
    width: 40px;
    font-size: 13px;
    color: #999;
    float: right;
    padding-right: 5px;
  }
</style>
