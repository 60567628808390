<template>
  <div>
    <div class="banner-web">
      <div class="banner-swiper">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item,index) in banner" :key="index">
            <a href="javascript:;" @click="urlInit(item.url)">
              <img :src="item.picurl" :alt="item.title" :style="{'height': imgH + 'px'}">
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"><i class="next-i"></i></div>
          <div class="swiper-button-next" slot="button-next"><i class="prev-i"></i></div>
          <!--      <div class="swiper-button-next"><i class="next-i"></i></div>-->
          <!--      <div class="swiper-button-prev"><i class="prev-i"></i></div>-->
        </swiper>
      </div>
    </div>

    <div class="banner-mobile">
      <div class="banner-swiper">
        <swiper :options="swiperOptionMobile">
          <swiper-slide v-for="(item,index) in banner" :key="index">
            <a href="javascript:;" @click="urlInit(item.url)">
              <img :src="item.picurl" :alt="item.title">
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"><i class="next-i"></i></div>
          <div class="swiper-button-next" slot="button-next"><i class="prev-i"></i></div>
          <!--      <div class="swiper-button-next"><i class="next-i"></i></div>-->
          <!--      <div class="swiper-button-prev"><i class="prev-i"></i></div>-->
        </swiper>
      </div>
    </div>
  </div>


</template>

<script>
//import axios from "axios";

export default {
  name: 'banner',
  props: {
    imgH: {
      type: String,
      default: '130'
    },
    module: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      banner: [],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionMobile: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    urlInit (url) {
      if (url) {
        url = url.substr(0, 4) === 'http' ? url : 'http://' + url
        window.open(url, '_blank')
      } else {
      }
    },
    getBanner () {
      this.$axios({
        method: "get",
        url: "web/newsBanner/list",
      }).then(res=>{
        this.banner = res.data.data /* 将拿到的值，赋值给data */
        console.log("banner...",this.banner);
      })
      // this.$app.api(`/news/web/newsBanner/list`)
      //   .then((r) => {
      //     if (r.data.code === 0) {
      //       this.banner = r.data.data
      //     }
      //   })
    }
  },
  mounted () {
    this.getBanner()
  }
}
</script>

<style scoped>
/*.banner{*/
/*  width: 100%;*/
/*}*/
/*.banner /deep/ img{*/
/*  width: 1920px;*/
/*  margin: 0 auto;*/
/*}*/
</style>
