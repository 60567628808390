import config from './config.js'
import Router from '../router'
import Vue from 'vue'
let vue = new Vue()

let userInfo = localStorage.getItem('$user_info');
let token = userInfo ? JSON.parse(userInfo).access_token : '';

let app = {
  /*
   * 获取当前时间 Y-M-D H:M:S
   * return {String} Y-M-D H:M:S
   * */
  formatDate: (d, isDay) => {
    let now;
    if(!d) {now = new Date()} else {now = new Date(d)};
    let year = now.getFullYear();
    let month = now.getMonth()+1;
    month = month < 10 ? '0' + month : month;
    let date = now.getDate();
    date = date < 10 ? '0' + date : date;
    let hour = now.getHours();
    hour = hour < 10 ? '0' + hour : hour;
    let minute = now.getMinutes();
    minute = minute < 10 ? '0' + minute : minute;
    let second = now.getSeconds();
    second = second < 10 ? '0' + second : second;
    if(isDay){
      return year+"-"+month+"-"+date;
    }
    return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;
  },

  /*
   * 获取当前时间戳
   * return {Number} nowTime 当前时间戳
   **/
  nowTime: () => {
    return new Date().getTime();
  },

  /*
   * toast
   * @param {String} msg 提示信息
   * @param {String} type 提示类型
   **/
  toast: (msg, type) => {
    vue.$message({showClose: true, message: msg, type: type});
  },

  /*
   * 验证邮箱、手机
   * @param {String} type 类型
   * @param {String} e 字符串
   * @returns {Bool} true/false
   **/
  testReg: (type, e) => {
    let bool, reg;
    switch(type){
      case 'mail':
        reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      break;

      case 'mobile':
        reg = /^1[3456789]\d{9}$/;
      break;
    }
    return reg.test(e);
  },

  /*
   * 用于文件前端生成uuid
   * @returns {string} uuid
   **/
  getUuid: () => {
    let s = [];
    let hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "";

    let uuid = s.join("");
    return uuid;
  },

  /*
   * 用来生成随机整数
   * @param {Number} n,m
   **/
	getRandom: (n, m) => {
		n = Number(n);
		m = Number(m);
		if (n > m) {
			let temp = n;
			n = m;
			m = temp;
		}
		return Math.floor(Math.random()*(m - n) + n);
	},

  /*
   * 获取随机整数
   * @return {String} str
   **/
	getCode: () => {
		let str = '', codeStr = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		for (let i = 0;i < 4;i ++) {
			let ran = app.getRandom(0, 62);
			str += codeStr.charAt(ran);
		}
		return str;
	},

  /*
   * 检查token
   * @return {String} token
   **/
  checkToken: (token) => {
  	if(!token){
      localStorage.setItem('$reload', 'reload');
  		Router.push('/');
  		return;
  	}
  },

  /*
   * 防恶意提交
   * @param {String} localType 缓冲Key
   * @param {String} str 提示语
   * @param {Number} T 时间间隔，默认3000毫秒
   **/
  conSub: (localType, str, T = 3000) => {
    let now = new Date().getTime(), time = localStorage.getItem(localType);
    if(time){
      if(now - time >= T) localStorage.removeItem(localType);
      if(str) app.toast(str, 'error');
      return 'returnIt';
    }else{
      localStorage.setItem(localType, now);
      let time = setInterval( () => {
        localStorage.removeItem(localType);
        clearInterval(time);
      }, T)
    }
  },

  /*
   * 数据请求封装
   * @param {String} url api链接
   * @param {Object} data 请求参数，默认{}
   * @param {String} type 请求方式，默认“GET”
   **/
  timer: '',
  userApi: (url, data = {}, type = "GET") => {
    let headers = {};
    if(!token){
      userInfo = localStorage.getItem('$user_info');
      token = userInfo ? JSON.parse(userInfo).access_token : '';
    }

    // 检查token
    app.checkToken(token);

    // 兼容不传data
    if(typeof data == "string") {
      type = data;
      data = {};
    }

    headers.Authorization = "Bearer " + token;

    // 非GET请求，防重复提交
    if(type != "GET"){
      let res = app.conSub('apiTime');
      if(res == 'returnIt'){
        let r = { data: { code: -1, msg: '请勿频繁提交' } };
        return new Promise( (res, rej) => { res(r) });
      };
    }

    return vue.$axios({
      method: type,
      url: `${config.basePath}${url}`,
      data: data,
      headers: headers,
    })
    .catch( (e) => {
      let msg = e.message;
      if(msg.indexOf('code') != -1 && msg.indexOf('401') != -1){
        clearTimeout(app.timer);
        app.timer = setTimeout( () => {
        	app.toast('身份验证已过期，请重新登录', 'error');
        	setTimeout( () => {
        	  localStorage.clear();
        	  localStorage.setItem('$reload', 'reload');
        	  Router.push('/');
        	}, 1000)
        },1)
      }else{
        return app.toast('网络错误', 'error');
      }
    })
  },

  /*
   * 数据请求封装
   * @param {String} url api链接
   * @param {Object} data 请求参数，默认{}
   * @param {String} type 请求方式，默认“GET”
   **/
  api: (url, data = {}, type = "GET") => {
    let headers = {};

    // 兼容不传data
    if(typeof data == "string") { type = data; data = {}; }

    // 非GET请求，防重复提交
    if(type != "GET"){
      let res = app.conSub('apiTime');
      if(res == 'returnIt'){
        let r = { data: { code: -1, msg: '请勿频繁提交' } };
        return new Promise( (res, rej) => { res(r) });
      };
    }

    return vue.$axios({
      method: type,
      url: `${config.basePath}${url}`,
      data: data,
      headers: headers,
    })
    .catch( (e) => {
      return app.toast('网络错误', 'error');
    })
  },

  /*
   * 登录跳转
   **/
  loginFun: () => {
    app.toast('您未登录，正在跳转登录链接');
    setTimeout( () => {
      window.location.href = `${config.basePath}/ssoLogin?platform=gz`;
    }, 1500)
  },

  /*
   * 图片报错
   **/
  imgError: (e) => {
    return require('../../static/images/defaultimg.png')
  },

  /*
   * 超出显示省略号
   * @param {String} str 原生字符串
   * @param {Number} len 字符长度
   **/
  stringFun: (str, len) => {
    if(str.length > len){
      str = str.substring(0, len) + '...';
    }
    return str
  },

  /*
   * 超出显示省略号
   * @param {String} str 原生字符串
   * @param {Number} len 字符长度
   **/
  reSetData: (dataList, num) => {
    let arr = [];
    let len = dataList.length;
    for (let i = 0; i < len; i += num) {
      arr.push(dataList.slice(i, i + num));
    }
    return arr;
  },

};


export default app;
