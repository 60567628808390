import Vue from 'vue'
import Router from 'vue-router'

//  首页
import home from '@/pages/home/home'
//  患者服务
import service from '@/pages/service/home'
//  新闻中心
import news from '@/pages/news/home'
//  健康园地
import health from '@/pages/health/home'


Vue.use(Router)

export default new Router({
  mode: 'history', // 去掉url中的#
  //配置滚动行为，跳转到新的路由界面滚动条的位置
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    /****************头部一级菜单开始*********************************/
    //  首页
    {
      path: '/',
      name: 'home',
      component: home
    },
    /*****************************************************************
     * 医院概况栏目
     *****************************************************************/
    //  医院介绍
    {
      path: '/introduce/yyjs',
      name: 'introduce_yyjs',
      component: () => import('@/pages/introduce/yyjs'),
    },
    //  领导班子
    {
      path: '/introduce/ldbz',
      name: 'introduce_ldbz',
      component: () => import('@/pages/introduce/ldbz'),
    },
    //  医院文化
    {
      path: '/introduce/yywh',
      name: 'introduce_yywh',
      component: () => import('@/pages/introduce/yywh'),
    },
    //  院训院旨
    {
      path: '/introduce/yxyz',
      name: 'introduce_yxyz',
      component: () => import('@/pages/introduce/yxyz'),
    },
    //  医院服务
    {
      path: '/service',
      name: 'service',
      component: service
    },
    /*****************************************************************
     * 就诊指南栏目
     *****************************************************************/
    //  科室介绍
    {
      path: '/office/ksjs',
      name: 'office_ksjs',
      component: () => import('@/pages/office/ksjs'),
    },
    //  专家介绍
    {
      path: '/office/zjjs',
      name: 'office_zjjs',
      component: () => import('@/pages/office/zjjs'),
    },
    //  医生介绍
    {
      path: '/office/ysjs',
      name: 'office_ysjs',
      component: () => import('@/pages/office/ysjs'),
    },
    //  门诊排班
    {
      path: '/office/mzpb',
      name: 'office_mzpb',
      component: () => import('@/pages/office/mzpb'),
    },
    /*****************************************************************
     * 就诊指南栏目
     *****************************************************************/
    //  名中医预约
    {
      path: '/guide/mzyyy',
      name: 'guide_mzyyy',
      component: () => import('@/pages/guide/mzyyy'),
    },
    //  交通指南
    {
      path: '/guide/jtzn',
      name: 'guide_jtzn',
      component: () => import('@/pages/guide/jtzn'),
    },
    //  门诊指南
    {
      path: '/guide/mzzn',
      name: 'guide_mzzn',
      component: () => import('@/pages/guide/mzzn'),
    },
    //  住院指南
    {
      path: '/guide/zyzn',
      name: 'guide_zyzn',
      component: () => import('@/pages/guide/zyzn'),
    },
    //  健康体检
    {
      path: '/guide/jktj',
      name: 'guide_jktj',
      component: () => import('@/pages/guide/jktj'),
    },
    //  医保流程
    {
      path: '/guide/yblc',
      name: 'guide_yblc',
      component: () => import('@/pages/guide/yblc'),
    },

    /*****************************************************************
     * 新闻中心栏目
     *****************************************************************/
    //  通知公告
    {
      path: '/news/tzgg',
      name: 'news_tzgg',
      component: ()=>import('@/pages/news/tzgg'),
    },
    //  医院新闻
    {
      path: '/news/yyxw',
      name: 'news_yyxw',
      component: ()=>import('@/pages/news/yyxw'),
    },
    //  招标采购
    {
      path: '/news/zbcg',
      name: 'news_zbcg',
      component: ()=>import('@/pages/news/zbcg'),
    },
    //  媒体聚焦
    {
      path: '/news/mtjj',
      name: 'news_mtjj',
      component: ()=>import('@/pages/news/mtjj'),
    },
    //  行业新闻
    {
      path: '/news/hyxw',
      name: 'news_hyxw',
      component: ()=>import('@/pages/news/hyxw'),
    },
    /*****************************************************************
     * 医院概况栏目
     *****************************************************************/
    //  党建工作
    {
      path: '/culture/djgz',
      name: 'culture_djgz',
      component: () => import('@/pages/culture/djgz'),
    },
    //  主题教育
    {
      path: '/culture/ztjy',
      name: 'culture_ztjy',
      component: () => import('@/pages/culture/ztjy'),
    },
    //  群团工作
    {
      path: '/culture/qtgz',
      name: 'culture_qtgz',
      component: () => import('@/pages/culture/qtgz'),
    },
    /*****************************************************************
     * 中医药科普栏目
     *****************************************************************/
    //  健康园地
    {
      path: '/health',
      name: 'health',
      component: health
    },
    //  护理知识
    {
      path: '/health/hlzs',
      name: 'health_hlzs',
      component: ()=>import('@/pages/health/hlzs'),
    },
    //  健康教育
    {
      path: '/health/jkjy',
      name: 'health_jkjy',
      component: ()=>import('@/pages/health/jkjy'),
    },
    /****************头部一级菜单结束*********************************/
    //  url新闻详情页
    {
      name: 'urlDetail',
      path: '/news/urlDetail',
      component: () => import('@/pages/news/urlDetail'),
      hidden: true
    },
    //  新闻详情页
    {
      name: 'newsDetail',
      path: '/news/detail/:id',
      component: () => import('@/pages/news/detail'),
      hidden: true
    },
    //  领导详情页
    {
      name: 'leaderInfo',
      path: '/introduce/leaderInfo/:id',
      component: () => import('@/pages/introduce/leaderInfo'),
      hidden: true
    },
    //  专家详情页
    {
      name: 'exportInfo',
      path: '/office/exportInfo/:id',
      component: () => import('@/pages/office/exportInfo'),
      hidden: true
    },
    //  医生详情页
    {
      name: 'doctorDetail',
      path: '/office/doctorDetail/:id',
      component: () => import('@/pages/office/doctorDetail'),
      hidden: true
    },
    //  科室详情页
    {
      name: 'officeInfo',
      path: '/office/officeInfo/:id',
      component: () => import('@/pages/office/officeInfo'),
      hidden: true
    },
  ]
})
