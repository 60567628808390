<template>
  <div>
    <div class="ind_nav">
      <div class="middle_navigation ind_nav_min">
        <ul>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="javascript:;" @click="clickLinkFun('guide_mzyyy')">
                <img :src="mzyyyPic">
                <p class="navigationname">名中医预约</p>
              </a>
            </div>
          </li>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="javascript:;" @click="clickLinkFun('guide_jtzn')">
                <img :src="jtznPic">
                <p class="navigationname">交通指南</p>
              </a>
            </div>
          </li>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="javascript:;" @click="clickLinkFun('guide_mzzn')">
                <img :src="mzznPic">
                <p class="navigationname">门诊指南</p>
              </a>
            </div>
          </li>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="javascript:;" @click="clickLinkFun('guide_zyzn')">
                <img :src="zyznPic">
                <p class="navigationname">住院指南</p>
              </a>
            </div>
          </li>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="javascript:;" @click="clickLinkFun('guide_jktj')">
                <img :src="jkznPic">
                <p class="navigationname">健康指南</p>
              </a>
            </div>
          </li>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="javascript:;" @click="clickLinkFun('guide_yblc')">
                <img :src="yblcPic">
                <p class="navigationname">医保流程</p>
              </a>
            </div>
          </li>
          <li class="navigationli lf">
            <div class="nav-item">
              <a href="https://www.gzszgqzyy.cn/statics/医药代表来访预约登记表.xlsx">
                <img :src="lfyyPic">
                <p class="navigationname">医药代表来访预约</p>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="min_nav">
          <ul class="clearfix">
<!--            <li class="border-b">-->
<!--              <a>-->
<!--                <div class="main">-->
<!--                  <p>门诊指南</p>-->
<!--                  <div class="en">REPORT QUERY</div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="border-b">-->
<!--              <a>-->
<!--                <div class="main">-->
<!--                  <p>门诊指南</p>-->
<!--                  <div class="en">REPORT QUERY</div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="border-b">-->
<!--              <a>-->
<!--                <div class="main">-->
<!--                  <p>门诊指南</p>-->
<!--                  <div class="en">REPORT QUERY</div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="border-b">-->
<!--              <a>-->
<!--                <div class="main">-->
<!--                  <p>门诊指南</p>-->
<!--                  <div class="en">REPORT QUERY</div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="border-b">-->
<!--              <a>-->
<!--                <div class="main">-->
<!--                  <p>门诊指南</p>-->
<!--                  <div class="en">REPORT QUERY</div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="border-b">-->
<!--              <a>-->
<!--                <div class="main">-->
<!--                  <p>门诊指南</p>-->
<!--                  <div class="en">REPORT QUERY</div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->
            <li class="border-b">
              <a href="javascript:;" @click="clickLinkFun('guide_mzyyy')">
                <img :src="mzyyyPic">
                <p>名中医预约</p>
              </a>
            </li>

            <li class="border-b" style="background: url(../../assets/images/mzyyy.jpg)">
              <a href="javascript:;" @click="clickLinkFun('guide_jtzn')">
                <img :src="jtznPic">
                <p>交通指南</p>
              </a>
            </li>

            <li class="border-b">
              <a href="javascript:;" @click="clickLinkFun('guide_mzzn')">
                <img :src="mzznPic">
                <p>门诊指南</p>
              </a>
            </li>

            <li class="border-b">
              <a href="javascript:;" @click="clickLinkFun('guide_zyzn')">
                <img :src="zyznPic">
                <p>住院指南</p>
              </a>
            </li>

            <li class="border-b">
              <a href="javascript:;" @click="clickLinkFun('guide_jktj')">
                <img :src="jkznPic">
                <p>健康指南</p>
              </a>
            </li>

            <li class="border-b">
              <a href="javascript:;" @click="clickLinkFun('guide_yblc')">
                <img :src="yblcPic">
                <p>医保流程</p>
              </a>
            </li>

<!--            <li class="border-b">-->
<!--              <a href="https://www.gzszgqzyy.cn/statics/医药代表来访预约登记表.xlsx">-->
<!--                <img :src="lfyyPic">-->
<!--                <p>医药代表来访预约</p>-->
<!--              </a>-->
<!--            </li>-->
          </ul>


<!--      <div class="min_nav_item">-->
<!--        <a href="javascript:;" @click="clickLinkFun('guide_mzyyy')">-->
<!--          <img :src="mzyyyPic">-->
<!--          <p class="navigationname">名中医预约</p>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="min_nav_item">-->
<!--        <a href="javascript:;" @click="clickLinkFun('guide_jtzn')">-->
<!--          <img :src="jtznPic">-->
<!--          <p class="navigationname">交通指南</p>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="min_nav_item">-->
<!--        <a href="javascript:;" @click="clickLinkFun('guide_mzzn')">-->
<!--          <img :src="mzznPic">-->
<!--          <p class="navigationname">门诊指南</p>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="min_nav_item">-->
<!--        <a href="javascript:;" @click="clickLinkFun('guide_zyzn')">-->
<!--          <img :src="zyznPic">-->
<!--          <p class="navigationname">住院指南</p>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="min_nav_item">-->
<!--        <a href="javascript:;" @click="clickLinkFun('guide_jktj')">-->
<!--          <img :src="jkznPic">-->
<!--          <p class="navigationname">健康指南</p>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="min_nav_item">-->
<!--        <a href="javascript:;" @click="clickLinkFun('guide_yblc')">-->
<!--          <img :src="yblcPic">-->
<!--          <p class="navigationname">医保流程</p>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="min_nav_item">-->
<!--        <a href="https://www.gzszgqzyy.cn/statics/医药代表来访预约登记表.xlsx">-->
<!--          <img :src="lfyyPic">-->
<!--          <p class="navigationname">医药代表来访预约</p>-->
<!--        </a>-->
<!--      </div>-->

    </div>
  </div>
</template>
<script>
export default {
  name: 'indNav',
  //  组件
  components: {
  },
  // computed: mapState(['token']),
  props: {
    // minW: {
    //   type: Number,
    //   default: 1200
    // }
  },
  data () {
    return {
      mzyyyPic: require('../../assets/images/mzyyy.jpg'),
      jtznPic: require('../../assets/images/jtznPic.png'),
      jkznPic: require('../../assets/images/jkznPic.png'),
      yblcPic: require('../../assets/images/yblcPic.png'),
      mzznPic: require('../../assets/images/mzzn.jpg'),
      zyznPic: require('../../assets/images/zyzn.jpg'),
      lfyyPic: require('../../assets/images/lfyy.jpg'),
    }
  },
  methods: {
    //  跳转新闻详情
    clickLinkFun(path){
      console.log(".....path....",path)
      if(path=="guide_yydblfyy")
      {

      }
      else
      {
        this.$router.push({ name: path});
      }

    },
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style scoped>


</style>
