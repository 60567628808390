<template>
  <div>
    <div class="ind_notice">
      <div class="notice_text">
        <el-carousel height="36px" direction="vertical" indicator-position="none" :autoplay="true">
          <el-carousel-item v-for="item in newsList" :key="item.id">
            <a href="javascript:;" @click="goToDtl(item.id)">{{item.title}}</a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <a class="notice_more" style="top: 32px;" href="javascript:;" @click="goToNewsCategory()">更多+</a>
    </div>
    <div class="min_notice">
<!--      <min-news-title :list="this.minNewsList" :title="this.minNewsTitle" :news-catetory="this.minNewsCategory"></min-news-title>-->
      <MinNews :newsInfoList="this.newsCategoryList"></MinNews>
<!--      <div class="notice_text">-->
<!--        <el-carousel height="22px" direction="vertical" indicator-position="none" :autoplay="true">-->
<!--          <el-carousel-item v-for="item in newsList" :key="item.id">-->
<!--            <a href="javascript:;" @click="goToDtl(item.id)">{{item.title}}</a>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
<!--      </div>-->

    </div>
  </div>

</template>
<script>
import MinNews from "@/components/minNews";
import MinNewsTitle from "@/components/minNewsTitle";
export default {
  name: 'indNotice',
  //  组件
  components: {
    MinNewsTitle,
    MinNews,
    // marqueeLed,
    // vueSeamlessScroll,
    // pmd,
  },
  computed: {

  },
  props: {
    // minW: {
    //   type: Number,
    //   default: 1200
    // }
  },
  data () {
    return {
      newsList:[],
      minNewsList:[],
      minNewsTitle:["通知公告"],
      minNewsCategory:"news_tzgg",
      newsCategoryList:[
        {
          showName: "通知公告",
          categoryName: "news_tzgg",
          categoryId:'1612698728566960130',
        },],
    }
  },
  methods: {
    // 跳转新闻详情
    goToNewsCategory() {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push({ name: this.minNewsCategory});
    },
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/news/detail/${id}`)
    },
    //  获取新闻列表
    getNewsList() {
      this.$axios({
        method: "get",
        url: "web/newsContent/list",
        params:{
          categoryId: '1612698728566960130',
          pageNum:1,
          pageSize:5
        }
      }).then(res => {
        this.newsList = res.data.rows /* 将拿到的值，赋值给data */
      })
    },

    // //  获取新闻列表
    // getMinNewsList() {
    //   this.$axios({
    //     method: "get",
    //     url: "web/newsContent/list",
    //     params:{
    //       categoryId: '1612698728566960130',
    //       pageNum:1,
    //       pageSize:3
    //     }
    //   }).then(res => {
    //     this.minNewsList = res.data.rows /* 将拿到的值，赋值给data */
    //   })
    // }
  },
  created () {
  },
  mounted () {
    // new WOW().init();
    this.getNewsList();
    this.getMinNewsList();
  }
}
</script>

<style scoped>


</style>
