<template>
  <div class="ind_new_office">
    <div class="ind_title">
      <img :src="leftPic">
      <span>&nbsp;&nbsp;科室介绍&nbsp;&nbsp;</span>
      <img :src="rightPic">
    </div>
    <div class="new_office_nav">
      <ul class="clearfix">
        <li v-for="(item,index) in officeList" :key="index">
          <a  href="javascript:;" @click="goToDtl(item.id)">
          <div class="title">{{item.officeName}}</div>
          <div class="en">{{item.officeEnName}}</div>
          </a>
        </li>
      </ul>
      <a href="/office/index.html" class="more">查看更多</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'indOffice',
  //  组件
  components: {
  },
  // computed: mapState(['token']),
  props: {
    // minW: {
    //   type: Number,
    //   default: 1200
    // }
  },
  data () {
    return {
      leftPic: require('../../assets/images/left.png'),
      rightPic: require('../../assets/images/right.png'),
      officeList: [],
    }
  },
  methods: {
    // 跳转新闻详情
    goToDtl(id) {
      // this.$store.commit('setNavActiveIndex', 'industry/1')
      // sessionStorage.setItem('setNavActiveIndex', 'industry/1')
      this.$router.push(`/office/officeInfo/${id}`)
    },
    //  获取科室列表
    getOfficeList () {
      this.$axios({
        method: "get",
        url: "web/hospitalOffice/list",
        params:{
          // parentId: parentId,
          selectSubOffice: 'Y',
          pageNum: 1,
          pageSize: 12
        }
      }).then(res=>{
        this.officeList = res.data.rows /* 将拿到的值，赋值给data */
        console.log("officeList...",this.officeList);
      })
    },
  },
  created () {
    this.officeList=[];
    //  获取临床科室列表
    this.getOfficeList();

  },
  mounted () {
  }
}
</script>

<style scoped>
.new_office_nav a:hover{
  background-color: #dd994e;
}

.new_office_nav li:hover{
  border-color: #dd994e;
}



</style>
